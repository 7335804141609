import React, {useState, useEffect} from 'react';
import Aux from '../../hoc/Aux/Aux';
import ProjectPlaceholder from '../../components/UI/Placeholder/ProjectPlaceholder/ProjectPlaceholder'
import Gallery from '../../components/Gallery/Gallery';
import GalleryItem from '../../components/Gallery/GalleryItem/GalleryItem';
import ProjectLogo from '../../components/ProjectLogo/ProjectLogo';
import ShareButton from '../../components/UI/SocialShareButton/SocialShareButton';
import ProjectLogoPlaceholder from '../../components/UI/Placeholder/ProjectLogoPlaceholder/ProjectLogoPlaceholder';

import {Row, Col, Alert, Button} from 'reactstrap';
import axios from '../../axios-base';
import moment from 'moment';
import SMSVote from "./SMSVote";
import FingerprintVote from "./FingerprintVote";
import VotePlaceholder from './VotePlaceholder';
import {Helmet} from "react-helmet";
import {NavLink, useParams} from 'react-router-dom';
import {sendVirtualPageView} from "../../shared/utility";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";

function Project(props){

    const [loadedPost, setLoadedPost] = useState(null);
    const [reCAPTCHAEnabled, setReCAPTCHAEnabled] = useState(Cookies.get('bigfoot_functional-consent') === 'true');
    const onAcceptHandler = () => {
        Cookies.set('bigfoot_functional-consent', true, {expires: 180})
        const cookieValue = Cookies.get('bigfoot_functional-consent');
        if (cookieValue !== undefined) {
            setReCAPTCHAEnabled(cookieValue === 'true');
        }
    };

    let { id } = useParams();
    useEffect(() => {
        if (id) {
            // @todo : implement Optional chaining for cleaner nested property comparison
            if (!loadedPost || (loadedPost && loadedPost.id !== id)) {
                axios.get(`/projects/${id}`) // leading slash for route is necessary for archival detection!
                    .then(({data}) => setLoadedPost(data))
                    .catch(error => {
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                        } else if (error.request) {
                            // The request was made but no response was received
                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                            // http.ClientRequest in node.js
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });

            }
            sendVirtualPageView(`/${id}`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

        let projectVoteCount = '-';
        let voteComponent;

        if (loadedPost && props.votingPhases) {
            const startDate = props.votingPhases.voteStart;
            if (!props.votingPhases.voteStarted) {
                // placeholder - prior to voting
                let text = 'Am ' + moment(startDate).format('DD.MM.') + ' geht\'s los';
                voteComponent = <VotePlaceholder text={text} />;
            } else if (props.votingPhases.voteEnded) {
                // placeholder - end of voting
                voteComponent = <VotePlaceholder text='Abstimmung ist beendet!' />;
            } else {
                // has reCAPTCHA been enabled?
                if (reCAPTCHAEnabled) {
                    // voting in progress
                    if (props.votingPhases.type.length > 1) {

                        // if finale started
                        if (!props.votingPhases.voteStartedFinale) {
                            voteComponent = <FingerprintVote {...props}/>;
                        } else {
                            // the finale has started
                            if (loadedPost.isFinalist) {
                                voteComponent = <SMSVote projectCategory={loadedPost.projectCategory} {...props} />;
                            } else {
                                voteComponent = <VotePlaceholder text='Leider nicht im Finale'/>;
                            }
                        }
                    } else {
                        if (props.votingPhases.type[0].phaseType === 'fingerprint') {
                            console.log("Fingerprint is loaded, ", props.votingPhases.type[0])
                            // Global Fingerprint contest
                            voteComponent = <FingerprintVote {...props}/>;
                        } else {
                            //Global SMS contest
                            voteComponent = <SMSVote projectCategory={loadedPost.projectCategory} {...props}/>;
                        }
                    }
                } else {
                    voteComponent =
                        <Alert color="warning">
                            <h4 className="alert-heading"><FontAwesomeIcon icon={faExclamationTriangle} className="me-1"/>Wir benötigen Ihre Zustimmung</h4>
                            <p>Für die Teilnahme an der Abstimmung ist Ihre Zustimmung zur Nutzung funktionaler Technologien erforderlich. Wir nutzen Google reCAPTCHA, um einen fairen Wettbewerb für alle Teilnehmenden zu gewährleisten.</p>
                            <Button block color="warning" onClick={onAcceptHandler} className="mb-3 anl_accept--functional">Dem Einsatz von reCAPTCHA zustimmen</Button>
                            <p>Details zu Verwendung Ihrer Daten finden Sie in unserer <NavLink exact to="/datenschutz">Datenschutzerklärung</NavLink>.
                               Des Weiteren gelten die <a className="anl_recaptcha--privacy" target="_blank" rel="noopener noreferrer" href="https://policies.google.com/privacy">Daten&shy;schutz&shy;erklärung</a> und
                               die <a className="anl_recaptcha--terms" target="_blank" rel="noopener noreferrer" href="https://policies.google.com/terms">Nutzungs&shy;bedingungen</a> von Google.</p>
                        </Alert>
                }
            }

            if(props.votingPhases.type && props.votingPhases.type.length > 1){
                if (props.votingPhases.voteStartedFinale) {
                    if (loadedPost.voteCountFinale && loadedPost.isFinalist) {
                        projectVoteCount = loadedPost.voteCountFinale;
                    }
                } else {
                    if (loadedPost.voteCount) {
                        //console.log('vote count geladen...');
                        projectVoteCount = loadedPost.voteCount;
                    }
                }
            } else {
                projectVoteCount = loadedPost.voteCountFinale;
            }
        }

        let project = (
            <Row className="py-3">
                <Col xs="12">
                    <ProjectPlaceholder/>
                </Col>
            </Row>
        );

        if (loadedPost) {

            project = (
                <Aux>
                    <Helmet>
                        <title>{`${process.env.REACT_APP_PROJECT_TITLE}: ${loadedPost.contestantName}`}</title>
                    </Helmet>
                    <Row className="my-3">
                        <Col xs="12" lg="5">
                            <Row className="py-3">
                                <Col>
                                    {loadedPost.projectLogo ?
                                        <ProjectLogo
                                            src={loadedPost.projectLogo}
                                            alt={loadedPost.title + ' Logo'}/>
                                        : <ProjectLogoPlaceholder/>}
                                </Col>
                                <Col className="d-flex flex-wrap align-self-center text-center">
                                    <h6 className="text-uppercase w-100">Stimmen</h6>
                                    <h1 className="w-100">{projectVoteCount}</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h1 className="text-primary">{loadedPost.contestantName}</h1>
                                    <h2 className="text-dark">{loadedPost.projectTitle}</h2>

                                </Col>
                            </Row>
                            <Row>
                                {voteComponent}
                                <Col xs="12" sm="6" lg="12" className="mb-2">
                                    <Row>
                                        <Col xs="6" lg="4" className="pb-1 pe-1 ps-3 pt-0 ps-md-1 ps-lg-3">
                                            <ShareButton shareType="facebook"/>
                                        </Col>

                                        <Col xs="6" lg="4" className="pb-1 pe-3 ps-1 pt-0 pe-md-1 pe-lg-3 d-md-none">
                                            <ShareButton
                                                shareTitle={loadedPost.contestantName}
                                                shareType="whatsApp" />
                                        </Col>

                                        <Col xs="6" lg="4" className="pb-1 pe-1 ps-3 pt-0 ps-md-1">
                                            <ShareButton
                                                shareTitle={loadedPost.contestantName}
                                                shareType="email"/>
                                        </Col>

                                        <Col xs="6" lg="4" className="pb-1 pe-3 ps-1 pt-0 ps-md-1 pe-lg-3">
                                            <ShareButton
                                                shareTitle={loadedPost.contestantName}
                                                shareType="twitter"/>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>

                        </Col>

                        <Col xs="12" lg="7">
                            <Row>
                                <Col xs={{size: 12, order: 2}} lg={{order: 1}}>
                                    <h1 className="text-primary">Dadurch zeichnet sich unser Projekt aus</h1>
                                    <p>{loadedPost.contestantAnswer1}</p>
                                </Col>

                                <Col xs={{size: 12, order: 3}} lg={{order: 2}}>
                                    <h1 className="text-primary">Kurzbeschreibung unserer Schule</h1>
                                    <p>{loadedPost.contestantAnswer2}</p>
                                </Col>

                                <Col xs={{size: 12, order: 1}} lg={{order: 3}} className="px-0 p-md-3">
                                    <Gallery>
                                        {loadedPost.projectGallery.map((galleryItem, index) => (
                                            <GalleryItem
                                                key={index}
                                                src={galleryItem}
                                                alt={'Galeriebild ' + index}/>
                                        ))}
                                    </Gallery>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Aux>
            )
        }

        return (
            <Aux>
                {project}
            </Aux>
        );
    }

export default Project;
