import React, {useState, useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {Container, Row} from 'reactstrap';

import axios from './axios-base';
import Aux from './hoc/Aux/Aux';
import Home from './containers/Home/Home';
import SignUp from './containers/SignUp/SignUp';
import ProjectList from './containers/ProjectList/ProjectList';
import Project from './containers/Project/Project';
import Page from './components/Page/Page';
import Auth from './containers/Auth/Auth';
import Logout from './containers/Auth/Logout/Logout';
import withErrorHandler from './hoc/withErrorHandler/withErrorHandler';
import * as actions from './store/actions/index';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ConsentModal from './components/UI/Modal/ConsentModal/ConsentModal';
import Cookies from 'js-cookie';
import {Helmet} from "react-helmet";
import {personalization, statisticTracking} from "./shared/trackingScripts";
import ScrollToTop from './shared/scrollToTop';
import PageNotFound from './components/Page/PageNotFound.js';
import SMSRequest from "./containers/SMSRequest/SMSRequest";

function App(props) {
    // Todo: move countdown dates to redux store and GET via axios
    // Todo: Refactor state object to more sensible naming conventions
    const [votingPhases, setVotingPhases] = useState({});
    const [pageNotFound, setPageNotFound] = useState(false);
    const [pages, setPages] = useState(null);
    const [navigation, setNavigation] = useState({});


    const pageNotFoundCallback = (pageState) => {
        setPageNotFound(pageState);
    };


    useEffect(() => {
        const phases = axios.get('/phases/')
        const pages = axios.get('/pages/')
        const navigation = axios.get('/menus/')

        Promise.all([phases, pages, navigation]).then(([resPhases, resPages, resNavigation]) => {
            if (process.env.REACT_APP_BUILD_FOR_ARCHIVAL === "true") {
                resNavigation.data = resNavigation.data.filter((item) => item.linkTarget !== "/bewerbung");
            }

            const primaryNavigation = resNavigation.data.filter(menuItem => {
                return menuItem.position === 'primary' && menuItem.highlight === false
            })
            const primaryNavigationCTA = resNavigation.data.filter(menuItem => {
                return menuItem.position === 'primary' && menuItem.highlight === true
            })
            const secondaryNavigation = resNavigation.data.filter(menuItem => {
                return menuItem.position === 'secondary'
            })
            setVotingPhases(resPhases.data);
            setPages(resPages.data);
            setNavigation({
                primaryNavigation: primaryNavigation,
                primaryNavigationCTA: primaryNavigationCTA,
                secondaryNavigation: secondaryNavigation
            })
        }).catch(error => {
            console.log(error);
        })
    }, [])

    let dynamicPages = null;
    let dynamicHeader = null;
    let consentManagementModal = null;
    let pageNotFoundRoute = null;

    // Define consent types and their associated scripts
    const consentTypes = [
        {
            key: 'bigfoot_personalization-consent',
            script: personalization,
        },
        {
            key: 'bigfoot_statistic-consent',
            script: statisticTracking,
        },
        {
            key: 'bigfoot_functional-consent',
            script: () => {return null},
        },
    ];

    // Filter the consent types based on existing consent cookies
    const activeConsents = consentTypes.filter((consentType) =>
        Cookies.get(consentType.key) === 'true'
    );

    // Generate Helmet elements for the active consent scripts
    dynamicHeader = (
        <Helmet>
            {activeConsents.map((consentType) => consentType.script())}
        </Helmet>
    );

    if (Cookies.get('bigfoot_cookie-consent') !== 'true') {
        consentManagementModal = <ConsentModal pages={pages}/>
    }

    if (pages) {
        pageNotFoundRoute =
            <Route path="*" status={404} render={() => <PageNotFound pageNotFoundCallback={pageNotFoundCallback}/>}/>;
        dynamicPages = (
            pages.map((page, i) => {
                return (
                    /* The following component is monitored by Font Awesome dom.watch(). Check children */
                    <Route
                        key={i}
                        exact //Caution: This may redirect to 404 pag if embedded links are opened from the page.
                        path={`/${page.slug}`}
                        render={(props) => <Row id="dom-watch"><Page pageTitle={page.title}
                                                                     pageBody={page.body} {...props}/></Row>}
                    />
                )
            })
        )
    }

    return (
        <Aux>
            <Container>
                {dynamicHeader}
                {consentManagementModal}
                <Header
                    votingPhases={votingPhases}
                    primaryNavigation={navigation.primaryNavigation}
                    primaryNavigationCTA={navigation.primaryNavigationCTA}
                    pageNotFound={pageNotFound}/>
                <ScrollToTop/>
                <Switch>
                    {dynamicPages}
                    <Route
                        path="/projekte/:id"
                        render={(props) => <Project votingPhases={votingPhases} {...props} />}/>
                    <Route path="/code-anfordern"
                           render={(props) => <SMSRequest votingPhases={votingPhases} {...props} />}/>
                    <Route path="/projekte" render={(props) => <ProjectList votingPhases={votingPhases}/>}/>
                    <Route path="/bewerbung" render={(props) => <SignUp {...props} />}/>
                    <Route path="/logout" component={Logout}/>
                    <Route path="/login" component={Auth}/>
                    <Route path="/" exact render={(props) => <Home votingPhases={votingPhases} {...props} />}/>
                    {pageNotFoundRoute}
                </Switch>
                <Footer secondaryNavigation={navigation.secondaryNavigation}/>
            </Container>
        </Aux>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
};

export default connect(null, mapDispatchToProps)(withErrorHandler(App, axios));
